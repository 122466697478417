.ui-card {
    .ui-card-head {
        & > h3 {
            padding: 0 3px 5px;
            margin: 0;
            font-size: 10px;
            color: #262c28;
        }
        & > p {
            padding: 0 0 5px;
            margin: 0;
            font-size: 19px;
            font-weight: bold;
            color: #459cff;
        }
    }

    .ui-card-body {
        margin: 2px;
        padding: 10px 8px;
        border-radius: 8px;
        box-shadow: #d7d7d7 0 0 2px;
        background: #fff;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            color: #9e9e9e;
            li {
                padding: 10px 0 6px;
                font-size: 12px;

                & + li {
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                }

                .name,
                .value {
                    display: inline-block;
                }
                .name {
                    width: 65px;
                }
            }
        }
    }
}

.ui-menu {
    .menu-item {
        display: inline-block;
        vertical-align: top;
        width: 82px;
        height: 20px;
        margin: 0 12px 0 0;
        padding: 0;
        border-radius: 6px;
        text-align: center;
        font-size: 11px;
        line-height: 20px;
        text-decoration: none;
        color: #262c28;
        background-color: #808080;
    }
}

.menu-model-wx {
    img {
        max-width: 195px;
    }
}

.r-b-d {
    position: relative;
    overflow: hidden;
}

.r-b-d > div,
.r-b-d > img,
.r-b-d > span {
    position: relative;
}

.r-b-d > .r-b-g {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #bbb;
    opacity: 0.2;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.r-b.disable {
    // background: #666;
}

/*.r-b.clicked{*/
/*background: #20b2aa;*/
/*}*/
.ui-img-loader {
    .ui-img,
    .ui-img-placeholder {
        width: 100%;
        // height: 100%;
        transition: all 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    .ui-img {
        display: none;
    }

    &.loaded {
        .ui-img {
            display: inline-block;
        }
    }
}

img.hidden {
    display: none;
}

.ui-official-footer {
    margin: 100px 0 0;
    text-align: center;
    color: #767d77;

    .line {
        width: 100%;
        height: 16px;
        margin: 25px 0 0;
        background: url(./img/line.png) no-repeat center top;
        background-size: 100% 16px;
    }

    .txt {
        margin: 0;
        padding: 25px 0 15px;
        font-size: 11px;
    }

    .copy-right {
        margin: 0;
        padding: 0 0 55px;
        font-size: 10px;

        a {
            color: #767d77;
        }
    }
}

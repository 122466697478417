.ui-official-header {
    width: 100%;

    .head-in {
        height: 226px;
        margin: -1px;
        padding: 0 33px;
        background: no-repeat center top;
        background-size: 100% 100%;

        .slogan {
            padding: 29px 0 0 0;
            text-align: left;

            img {
                width: 335px;
            }
        }

        .logo {
            padding: 28px 0 0 0;
            font-size: 18px;
            text-align: right;
            color: #fff;
        }
    }

    .menu {
        .menu-wrap {
            max-width: 1280px;
            margin: 0 auto;
        }

        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #262c28;

        a {
            display: inline-block;
            width: 16.66%;
            font-size: 15px;
            vertical-align: top;
            text-decoration: none;
            color: #fff;
        }
    }

    .head-title {
        background: #f1f1f1;
        & > h2 {
            padding: 50px 0 45px;
            margin: 0;
            font-size: 31px;
            text-align: center;
            color: #3f4540;
        }

        .head-title-arrow {
            width: 100%;
            height: 16px;
            // margin: 25px 0 0;
            background: url(./img/gray_line.png) no-repeat center top;
            background-size: 100% 16px;
        }
    }
}

.p-login {
    color: #262c28;
    background: #262c28;

    header {
        height: 234px;
        background: url(./img/head_bg.png) no-repeat top center;
        background-size: 100% 100%;

        .slogan {
            padding: 40px 0 0 28px;
        }
    }

    section {
        max-width: 1200px;
        margin: 0 auto;
        .content-in {
            margin: 0 auto;
        }

        .main {
            padding: 48px 0 0 178px;
            img {
                width: 100%;
                max-width: 522px;
                height: auto;
            }

            .ui-menu {
                margin: 27px 0 70px 14px;
                .menu-item {
                    &:nth-child(1) {
                        background-color: #bcd4e1;
                    }

                    &:nth-child(2) {
                        background-color: #b6a0aa;
                    }

                    &:nth-child(3) {
                        margin: 0 25px 0 0;
                        background-color: #cac8ae;
                    }
                }
            }
        }

        .qrcode {
            width: 371px;
            #qrcode {
                & > * {
                    max-width: 195px;
                    max-height: 250px;
                    margin: 138px 0 0 0;
                    background-color: #fff;
                }
            }
        }
    }
}

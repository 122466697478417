.student {
    .ui-card-body {
        height: 208px;

        .ui-scroll {
            height: 145px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            .ui-scroll-in {
                top: 7px;
                bottom: 7px;
                left: 7px;
            }
        }
        .students-list {
            font-size: 8px;

            & > div {
                &.sign {
                    color: rgba($color: #459cff, $alpha: 0.5);
                }

                &.work {
                    color: #459cff;
                }

                & + div {
                    padding-top: 6px;
                }
            }
        }
    }
}

.ui-official-layout {
    &.declare {
        font-size: 14px;
        color: #262c28;

        .ui-official-content {
            max-width: 720px;
            margin: 60px auto 0;

            p {
                line-height: 1.6;

                & + p {
                    margin-top: 20px;
                }
            }
        }
    }
}

.ui-official-layout {
    // background: #f1f1f1;
    .ui-header {
        .ui-menu {
            margin: 10px 0 30px 28px;
        }
    }

    .ui-official-content {
        max-width: 1000px;
        margin: 0 auto;
    }

    .ui-footer {
    }
}

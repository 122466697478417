.ui-video {
    position: relative;
    height: 100%;
    text-align: center;
    overflow: hidden;
    background: #000;

    & > img {
        position: absolute;
        top: 50%;
        left: 50%;
        // width: auto;
        // height: 100%;
        width: 100%;
        height: auto;
        transform: translate(-50%, -50%);

        &.long-img {
            // width: 100%;
            // height: auto;
            width: auto;
            height: 100%;
        }
    }
}

.ui-scroll {
    position: relative;
    padding-right: 6px;

    .ui-scroll-in {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 2px;
        }

        // &::-webkit-scrollbar-button {
        //     height: 4px;
        // }

        // &::-webkit-scrollbar-track {
        //     background: url(./img/scrollbar_track.png) no-repeat center center;
        // }

        &::-webkit-scrollbar-thumb {
            // width: 4px;
            // height: 4px;
            // background: url(./img/scrollbar_thumb.png) no-repeat center top;
            // background-size: 6px auto;
            background-color: #c7c7c7;
        }
    }
}

.react-popup {
    position: relative;
    margin: 0 auto;
    width: 300px;
    max-height: 100%;
    overflow: auto;
    padding: 16px;
    border-radius: 6px;
    background-color: #f1f1f1;

    &.react-popup-l {
        width: 500px;

        .react-pop-body {
            .react-pop-content {
                // height: 600px;
                overflow: auto;
            }
        }
    }

    .react-pop-head {
        height: 30px;
        font-size: 12px;
        text-align: center;
        line-height: 30px;
        color: #262c28;
    }

    .react-pop-body {
        .react-pop-content {
            padding: 16px;
            font-size: 12px;
            color: #262c28;
        }

        .react-pop-buttons {
            padding: 16px 16px 0;
            overflow: visible;
            text-align: center;

            .button {
                overflow: visible;
                display: inline-block;
                width: 82px;
                height: 20px;
                border-radius: 6px;
                font-size: 11px;
                line-height: 20px;
                vertical-align: middle;
                color: #f1f1f1;
                background: #262c28;
                cursor: pointer;

                & + .button {
                    margin-left: 10px;
                }

                &.single-btn {
                    width: 85px;
                    height: 20px;
                    margin-right: 0;
                    line-height: 21px;
                }
            }
        }
    }
}

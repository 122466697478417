.page-course {
    .ui-loading {
        padding: 240px 0;
    }

    .ui-header {
        .ui-menu {
            .menu-item {
                &:nth-child(1) {
                    color: #f1f1f1;
                    background: #262c28;
                }

                &:nth-child(2) {
                    background: #cac8ae;
                }
            }
        }
    }

    .ui-content {
        min-height: 450px;
        padding: 0 0 42px;
        .left {
            margin: 0 30px 0 28px;
            width: 800px;
            border-radius: 2px;
        }

        .left {
            // min-height: 600px;
            .video-content {
                height: 470px;
            }

            .video-tools {
                width: 300px;
                margin: 18px auto 0;
                .video-tool-btn {
                    width: 82px;
                    height: 20px;
                    font-size: 11px;
                    line-height: 20px;
                    text-align: center;
                    opacity: 0;
                    cursor: initial;

                    &.pre,
                    &.next {
                        border-radius: 6px;
                        cursor: pointer;
                        color: #262c28;
                        background-color: #63e7b0;
                        opacity: 1;
                    }
                }

                .video-tool-text {
                    font-size: 14px;
                    text-align: center;
                }
            }
        }

        .right {
            width: 216px;
            // margin: 0 0 0 30px;
        }
    }
}

.course-list-model {
    text-align: center;
}

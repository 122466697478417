.ui-timer {
    // padding: 0 0.2rem 0.02rem 0.1rem;
    display: inline-block;
    font-size: 19px;
    font-weight: bold;

    .time {
        position: relative;
        display: inline-block;
        color: #459cff;
        background: #fff;

        .desc {
            position: absolute;
            top: 0.36rem;
            font-weight: normal;
            color: #459cff;
            text-align: center;
        }
    }

    .colon {
        color: #459cff;
    }
}

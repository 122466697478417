.ui-course-model {
    width: 400px;
    padding: 0 16px 16px;
    border-radius: 6px;
    background: #f1f1f1;

    .ui-model-title {
        height: 50px;
        font-size: 14px;
        line-height: 50px;
        text-align: center;
        color: #262c28;
    }

    .ui-card.info {
        .ui-card-body {
            margin: 0;
            text-align: left;

            ul {
                li {
                    margin: 0 10px;
                }
            }
        }
    }

    .btn-confirm {
        width: 300px;
        height: 30px;
        margin: 30px auto 0;
        border-radius: 4px;
        line-height: 30px;
        background: #262c28;
        color: #f1f1f1;
    }
}

.react-model {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 100%;

    &.mask {
        background: rgba(0, 0, 0, 0.5);
        /*  transform: translateZ(0); */
    }

    &:before {
        content: "\200B";
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }

    & > div {
        margin-left: auto;
        margin-right: auto;
        // width: 100%;
        display: inline-block;
        vertical-align: middle;
        // text-align: center;
    }

    &.bottom > div {
        position: fixed;
        bottom: 0;
        left: 0;
    }

    &._top > div {
        position: absolute;
        top: 0;
        left: 0;
    }

    &.model-center {
        text-align: center;
    }
}

/*! https://github.com/lzxb/flex.css */
[data-flex] {
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

[data-flex] > * {
    display: block;
    overflow: hidden
}

[data-flex] > [data-flex] {
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

[data-flex~="dir:left"] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row
}

[data-flex~="dir:right"] {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: end
}

[data-flex~="dir:top"] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

[data-flex~="dir:bottom"] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: end
}

[data-flex~="main:left"] {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

[data-flex~="main:right"] {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

[data-flex~="main:justify"] {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

[data-flex~="main:center"] {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

[data-flex~="cross:top"] {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start
}

[data-flex~="cross:bottom"] {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    -ms-grid-row-align: flex-end;
    align-items: flex-end
}

[data-flex~="cross:center"] {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center
}

[data-flex~="cross:baseline"] {
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    -ms-grid-row-align: baseline;
    align-items: baseline
}

[data-flex~="cross:stretch"] {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    -ms-grid-row-align: stretch;
    align-items: stretch
}

[data-flex~="box:mean"] > *, [data-flex~="box:first"] > *, [data-flex~="box:last"] > *, [data-flex~="box:justify"] > * {
    width: 0;
    height: auto;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1
}

[data-flex~="box:first"] > :first-child, [data-flex~="box:last"] > :last-child, [data-flex~="box:justify"] > :first-child, [data-flex~="box:justify"] > :last-child {
    width: auto;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

[data-flex~="dir:top"][data-flex~="box:mean"] > *, [data-flex~="dir:top"][data-flex~="box:first"] > *, [data-flex~="dir:top"][data-flex~="box:last"] > *, [data-flex~="dir:top"][data-flex~="box:justify"] > *, [data-flex~="dir:bottom"][data-flex~="box:mean"] > *, [data-flex~="dir:bottom"][data-flex~="box:first"] > *, [data-flex~="dir:bottom"][data-flex~="box:last"] > *, [data-flex~="dir:bottom"][data-flex~="box:justify"] > * {
    width: auto;
    height: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1
}

[data-flex~="dir:top"][data-flex~="box:first"] > :first-child, [data-flex~="dir:top"][data-flex~="box:last"] > :last-child, [data-flex~="dir:top"][data-flex~="box:justify"] > :first-child, [data-flex~="dir:top"][data-flex~="box:justify"] > :last-child, [data-flex~="dir:bottom"][data-flex~="box:first"] > :first-child, [data-flex~="dir:bottom"][data-flex~="box:last"] > :last-child, [data-flex~="dir:bottom"][data-flex~="box:justify"] > :first-child [data-flex~="dir:bottom"][data-flex~="box:justify"] > :last-child {
    height: auto;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

[data-flex-box="0"] {
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

[data-flex-box="1"] {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1
}

[data-flex-box="2"] {
    -webkit-box-flex: 2;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    -webkit-flex-shrink: 2;
    -ms-flex-negative: 2;
    flex-shrink: 2
}

[data-flex-box="3"] {
    -webkit-box-flex: 3;
    -webkit-flex-grow: 3;
    -ms-flex-positive: 3;
    flex-grow: 3;
    -webkit-flex-shrink: 3;
    -ms-flex-negative: 3;
    flex-shrink: 3
}

[data-flex-box="4"] {
    -webkit-box-flex: 4;
    -webkit-flex-grow: 4;
    -ms-flex-positive: 4;
    flex-grow: 4;
    -webkit-flex-shrink: 4;
    -ms-flex-negative: 4;
    flex-shrink: 4
}

[data-flex-box="5"] {
    -webkit-box-flex: 5;
    -webkit-flex-grow: 5;
    -ms-flex-positive: 5;
    flex-grow: 5;
    -webkit-flex-shrink: 5;
    -ms-flex-negative: 5;
    flex-shrink: 5
}

[data-flex-box="6"] {
    -webkit-box-flex: 6;
    -webkit-flex-grow: 6;
    -ms-flex-positive: 6;
    flex-grow: 6;
    -webkit-flex-shrink: 6;
    -ms-flex-negative: 6;
    flex-shrink: 6
}

[data-flex-box="7"] {
    -webkit-box-flex: 7;
    -webkit-flex-grow: 7;
    -ms-flex-positive: 7;
    flex-grow: 7;
    -webkit-flex-shrink: 7;
    -ms-flex-negative: 7;
    flex-shrink: 7
}

[data-flex-box="8"] {
    -webkit-box-flex: 8;
    -webkit-flex-grow: 8;
    -ms-flex-positive: 8;
    flex-grow: 8;
    -webkit-flex-shrink: 8;
    -ms-flex-negative: 8;
    flex-shrink: 8
}

[data-flex-box="9"] {
    -webkit-box-flex: 9;
    -webkit-flex-grow: 9;
    -ms-flex-positive: 9;
    flex-grow: 9;
    -webkit-flex-shrink: 9;
    -ms-flex-negative: 9;
    flex-shrink: 9
}

[data-flex-box="10"] {
    -webkit-box-flex: 10;
    -webkit-flex-grow: 10;
    -ms-flex-positive: 10;
    flex-grow: 10;
    -webkit-flex-shrink: 10;
    -ms-flex-negative: 10;
    flex-shrink: 10
}